import { render, staticRenderFns } from "./SessionSpeakerAlt.vue?vue&type=template&id=45fbd60c&scoped=true"
import script from "./SessionSpeakerAlt.vue?vue&type=script&lang=js"
export * from "./SessionSpeakerAlt.vue?vue&type=script&lang=js"
import style0 from "./SessionSpeakerAlt.vue?vue&type=style&index=0&id=45fbd60c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45fbd60c",
  null
  
)

export default component.exports